<template>
  <table width="768" style="font-family:Tahoma;color: #000000">
    <tr>
       <td>
        <table width="100%">
           <tr>
              <td width="30" style="height: 10px"></td>
              <td><a href="https://westernopticals.com" target="_blank"><img src="https://westernopticals.com/wp-content/uploads/2019/06/logo-2.png" alt="westernopticals.com" width="291" height="90"></a> </td>
              <td align="right" valign="middle" style="font-size: 19px"><br/> RECEIPT:   <strong>WO20070020</strong><br/>
               ORDER DATE    : <strong>07/15/2020</strong><br/>
               ORDER NUMBER  : <strong>2197929408</strong><br/>
               FEDERAL TAX ID: <strong>84-4450284</strong>
               </td>
              <td width="30" ></td>
            </tr>
           </table>
                </td>
    </tr>
   
    <tr> <td>
        <table  width="100%">
           <tr>
               <td width="30"></td>
               <td style="font-size: 26px;border-bottom: 1px solid #000000;padding: 5px 0 10px;"><strong>Your Order Receipt</strong></td>
               <td width="30"></td>
            </tr>
           <tr>
               <td></td>
               <td style="line-height:35px;padding: 10px 0 0">
                Thank you for purchase from <strong>westernopticals.com</strong><br/>
               
                 
                 </td>
               <td></td>
            </tr>
            <tr><td colspan="3"></td></tr>
             <tr>
                <td></td>
                <td style="font-size: 22px"><strong> FROM</strong><br/>
                 Western Opticals Inc, <br/>
                 1 Waterview Dr #102, <br/>
                 Shelton, CT 06484
                 
                 </td>
                <td></td>
            </tr>
             <tr>
                <td></td>
                <td style="height: 10px"></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td style="font-size: 22px"><strong>SHIP TO</strong><br/>
                 Minimol Biju, <br/>
                 85 Frelma Dr, <br/>
                 Trumbull, CT 06611
                 
                </td>
                <td></td>
            </tr>
             <tr>
                <td></td>
                <td>&nbsp;</td>
                <td></td>
            </tr>
            
             <tr>
                <td></td>
                <td>
                 <table width="100%">
                      <tr style="font-size: 18px;">
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>PURPOSE</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>P.O.NUMBER</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>COD</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>SHIPPED VIA</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px" align="right"><strong>TERMS</strong></td>
                     </tr>
                     <tr>
                       <td style="border-bottom: 1px solid #ccc;padding:  10px">Sale</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px"></td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px">USA</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px">USPS</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px" align="right"></td>
                     </tr>
                    
                    </table>
                 
                 </td>
                <td></td>
            </tr>
             <tr>
                <td></td>
                <td>&nbsp;</td>
                <td></td>
            </tr>
            
             <tr>
                <td></td>
                <td>
                 <table width="100%">
                      <tr style="font-size: 18px;">
                        <td style="border-bottom: 1px solid #000000;padding:  10px"  width="25"><strong>Qty</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>Product Detail</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px"><strong>PRICE</strong></td>
                        <td style="border-bottom: 1px solid #000000;padding:  10px" align="right"><strong>TOTAL</strong></td>
                     </tr>
                     <tr>
                       <td style="border-bottom: 1px solid #ccc;padding:  10px">1</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px">Frames:<br/>
                                                                                  Fiona Pilfer - Wine Reed<br/>
										  TW18036 - Eyeglasses <br/>
                                                                                  SKU:FP_TW18036_C1  <br/>
										  Discount 20%	</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px"> <br/>$119.99<br/><br/><br/>$-24.00</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px" align="right">$95.99</td>
                     </tr>
                    <tr>
                       <td style="border-bottom: 1px solid #ccc;padding:  10px">1</td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px">Lenses:<br/>
                                                                                  Single Vision<br/>
                                                                                  Polycarbonate <br/> 
										  Premium Anti Scrach<br/>
										  Primium Anti Reflection<br/>
										  Premium UV Coating<br/>
										  Discount 10%	
                        <td style="border-bottom: 1px solid #ccc;padding:  10px"> <br/>$29.00<br/>$18.95<br/>$9.95<br/>$10.00<br/>$9.95<br/>-$7.79<br/></td>
                        <td style="border-bottom: 1px solid #ccc;padding:  10px" align="right">$70.06</td>
                     </tr>
                     <tr>
                        <td style="border-bottom: 1px solid #000000;"></td>
                        <td style="border-bottom: 1px solid #000000;"></td>
                        <td  style="line-height: 30px;padding: 10px;font-size:20px;border-bottom: 1px solid #000000;" >
                            Sub Total<br/>
                            Shipping & Handling<br/>
                            
                            Tax
                         </td>
                        <td style="line-height: 30px;padding: 10px;font-size: 20px;border-bottom: 1px solid #000000;" align="right">
                            $166.06<br/>
                            $0.00<br/>
                            
                            $10.54
                         </td>
                     </tr>
                     <tr>
                        <td style="line-height: 30px;padding: 10px;border-bottom: 1px solid #000000;" ></td>
                        <td style="line-height: 30px;padding: 10px;border-bottom: 1px solid #000000;" ></td>
                        <td style="line-height: 30px;padding: 10px;font-size: 25px;border-bottom: 1px solid #000000;" >
                         Total
                         </td>
                        
                        <td style="line-height: 30px;padding: 10px;font-size: 25px;border-bottom: 1px solid #000000;"  align="right"><strong>$176.59</strong></td>
                     </tr>
                    </table>
                 
                 </td>
                <td></td>
            </tr>
        </table>
        
        </td> </tr>
    <tr> <td bgcolor="#210070" style="padding:15px 10px; color: #ffffff" align="center">
        <strong>Email </strong>info@westernopticals.com
        <strong>Phone</strong> +(203) 5138302
        </td> </tr>
    <tr> <td></td> </tr>
    <tr> <td></td> </tr>

</table>
</template>

<script>
export default {

}
</script>

<style>

</style>